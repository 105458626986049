// UTM-tags
const utm = ['utm_source', 'utm_medium', 'utm_campaign', 'custom_source', 'custom_medium', 'custom_campaign', 'utm_content', 'utm_term'];
let utmCookie = {};
let date = new Date();
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const ref = document.referrer ? new URL(document.referrer) : null;

date.setDate(date.getDate() + 60);

for (const entry of urlParams.entries()) {
    if (utm.indexOf(entry[0]) > -1) {
        utmCookie[entry[0]] = entry[1];
    }
}

if (ref) {
    const parsedParams = ref.searchParams
    .toString()
    .split('&')
    .reduce((previous, current) => {
        const [key, value] = current.split('=');
        previous[key] = value;
        return previous;
    }, {});

    utmCookie = {...utmCookie, ...ref.host && {referrer: ref.host}, ...parsedParams.q && {query: parsedParams.q}};
}

if (!document.cookie.match(/^(.*;)?\s*rollerads_utm\s*=\s*[^;]+(.*)?$/)) {
    document.cookie = "rollerads_utm=" + JSON.stringify(utmCookie) + "; path=/; expires=" + date  + "; domain=.rollerads.com; Secure"
}